<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#313189" />
    <div v-else class="privacy">
      <!-- header section -->
      <section class="privacy__header">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-7">
              <div v-if="header_title.length > 0" v-html="$prismic.asHtml(this.header_title)" class="header__title"></div>
              <div v-if="header_text.length > 0" v-html="$prismic.asHtml(this.header_text)" class="header__text"></div>
            </div>
          </div>
        </div>
      </section>
      <!-- impressum section -->
      <section class="privacy__content">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-8">
              <div v-html="$prismic.asHtml(this.content)"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
  name: 'ImpressumPage',
  components: {
    Spinner
  },
  data: () => {
    return {
      cmsContentPending: false,
      header_title: null,
      header_text: null,
      content: null
    }
  },
  methods: {
    async getImpressumContent () {
      await this.$prismic.client.getSingle('impressumpage', { lang: this.$i18n.lang })
        .then((document) => {
          // header section
          this.header_title = document.data.header_title
          this.header_text = document.data.header_text
          // content section
          this.content = document.data.content
          this.cmsContentPending = true
        })
    }
  },
  created () {
    this.getImpressumContent()
  },
  watch: {
    '$i18n.locale': function (newValue) {
      this.getImpressumContent()
    }
  }
}
</script>
